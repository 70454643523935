<!--
 * @Description: 导航条
 * @Author: zhang zhen
 * @Date: 2023-02-03 14:02:03
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-07 11:33:55
 * @FilePath: /page-sass/src/components/tools/elevator.vue
-->
<template>
    <div class="elevator-list">
        <div class="elevator-list-item" @click="handleElevator">
            <img src="~@/assets/elevator/icon_1.png" alt="" class="normal" />
            <img src="~@/assets/elevator/icon_active_1.png" alt="" class="info" />
            <span class="label">反馈</span>
            <!-- <img src="~@/assets/elevator/info_1.png" alt="" class="Icon" /> -->
        </div>
        <div class="elevator-list-item">
            <img src="~@/assets/elevator/icon_2.png" alt="" class="normal" />
            <img src="~@/assets/elevator/icon_active_2.png" alt="" class="info" />
            <!-- <img src="~@/assets/elevator/info_2.png" alt="" class="Icon" /> -->
            <span class="label">帮助</span>


        </div>
        <div class="elevator-list-item" @click="handleEmitChat">
            <img src="~@/assets/elevator/icon_5.png" alt="" class="normal" />
            <img src="~@/assets/elevator/icon_active_5.png" alt="" class="info" />
            <!-- <img src="~@/assets/elevator/info_5.png" alt="" class="Icon" /> -->

            <span class="label">服务中心</span>

        </div>
        <ElevatorForm ref="ElevatorFormRef" />
    </div>
</template>

<script>
import ElevatorForm from './ElevatorForm.vue'
export default {
    name: "ElevatorList",
    components: {
        ElevatorForm,
    },
    data() {
        return {

        }
    },
    methods: {
        handleElevator() {
            this.$refs.ElevatorFormRef.handleOpen()
        },
        handleEmitChat() {
            _MEIQIA('showPanel') 
        },
    },
}
</script>

<style lang="less" scoped>
.elevator-list {
    box-sizing: border-box;
    position: fixed;
    bottom: 90px;
    right: 0;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    overflow: hidden;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    &-item {
        width: 56px;
        position: relative;
        height: 62px;
        cursor: pointer;

        .normal {
            width: 32px;
            height: 32px;
            position: absolute;
            left: 50%;
            top: 7px;
            transform: translateX(-50%);
        }

        .info {
            width: 32px;
            height: 32px;
            position: absolute;
            left: 50%;
            top: 7px;
            transform: translateX(56px);
        }

        .Icon {
            width: auto;
            height: 26px;
            position: absolute;
            right: -300px;
            top: 50%;
            transform: translateY(-50%);
        }

        span.label {
            font-family: PingFang SC;
            font-size: 12px;
            font-weight: 500;
            line-height: 10px;
            text-align: left;
            color: #8D8E99;
            position: absolute;
            left: 50%;
            bottom: 8px;
            display: inline-block;
            width: max-content;
            transform: scale(0.9) translateX(-50%);
        }

        &:hover {
            background-color: #FF60261F;
            transition: all ease-in 500ms;

            .info {
                transform: translateX(-50%);

            }

            .normal {
                transform: translateX(56px);
            }

            .Icon {
                transition: all ease-in 500ms;
                right: calc(100% + 4px);
            }
            span.label {
                color: #FF6026;
            }
        }
    }
}
</style>